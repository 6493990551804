import React from 'react';
import { Menu, Segment, Loader, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import Incident from './incident/Incident';
import AddOptions from './addOptions/AddOptions';
import DistanceAndIgnitionReport from './DistanceAndIgnitionReport';
import ServicingInfo from './servicingInfo/ServicingInfo';
import DeliveryVanInformation from './deliveryVanInformation/DeliveryVanInformation';
import SpareParts from './spareParts/SpareParts';

class VMS extends React.Component {
  state = {
    activeMainItem: '',
    plateNumberList: [],
    plateNumberLoaded: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setAssetList();
    }, 2000);
  }

  setAssetList = () => {
    const assetList = JSON.parse(window.localStorage.getItem('assetList'));
    this.setState({
      plateNumberList: assetList
        .filter((item) => item.active)
        .map((item) => ({
          key: item.key,
          value: item.value,
          text: item.label,
        })),
      plateNumberLoaded: true,
    });
  };

  handleEdit = (type, task, action) => {
    this.setState({ editEvent: type, task, activeItem: action });
  };

  handleItemClick = (e, { name }) => this.setState({ activeMainItem: name });

  showVms = () => {
    const { org = '' } = this.props.match.params;

    // if (
    //   ['5bc6dedd1d41c87634325760'].includes(
    //     window.localStorage.getItem('organization')
    //   ) &&
    //   org === 'transcom'
    // ) {
    //   return true;
    // }
    // if (
    //   ['5638fec0421aa95226c374cf'].includes(
    //     window.localStorage.getItem('organization')
    //   ) &&
    //   org === 'jayson'
    // ) {
    //   return true;
    // }

    if (
      ['5bc6dedd1d41c87634325760'].includes(
        window.localStorage.getItem('organization'),
      ) &&
      org === 'transcom'
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { activeMainItem, plateNumberList, plateNumberLoaded } = this.state;
    const { org = '' } = this.props.match.params;

    return this.showVms() ? (
      <Segment>
        <div>
          {plateNumberLoaded ? (
            <div>
              <Menu tabular stackable fluid style={{ overflowX: 'auto' }}>
                <Menu.Item
                  name='Incident Report'
                  active={activeMainItem === 'Incident Report'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='Servicing Information'
                  active={activeMainItem === 'Servicing Information'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='Delivery Van Information'
                  active={activeMainItem === 'Delivery Van Information'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='Spare Parts'
                  active={activeMainItem === 'Spare Parts'}
                  onClick={this.handleItemClick}
                />
                {['5bc6dedd1d41c87634325760'].includes(
                  window.localStorage.getItem('organization'),
                ) && (
                  <Menu.Item
                    name='Distance and Ignition Report'
                    active={activeMainItem === 'Distance and Ignition Report'}
                    onClick={this.handleItemClick}
                  />
                )}
                <Menu.Item
                  name='Add Options'
                  active={activeMainItem === 'Add Options'}
                  onClick={this.handleItemClick}
                />
              </Menu>

              {!activeMainItem && (
                <Header as='h3' textAlign='center'>
                  ↑
                  <br />
                  Select an Item
                </Header>
              )}

              {activeMainItem === 'Incident Report' && (
                <Incident plateNumbers={plateNumberList} org={org} />
              )}

              {activeMainItem === 'Servicing Information' && (
                <ServicingInfo plateNumberList={plateNumberList} org={org} />
              )}

              {activeMainItem === 'Delivery Van Information' && (
                <DeliveryVanInformation
                  plateNumberList={plateNumberList}
                  org={org}
                />
              )}

              {activeMainItem === 'Spare Parts' && (
                <SpareParts plateNumberList={plateNumberList} org={org} />
              )}

              {activeMainItem === 'Distance and Ignition Report' && (
                <DistanceAndIgnitionReport
                  plateNumbers={plateNumberList}
                  org={org}
                />
              )}

              {activeMainItem === 'Add Options' && (
                <AddOptions plateNumbers={plateNumberList} org={org} />
              )}
            </div>
          ) : (
            <Loader active inline='centered' />
          )}
        </div>
      </Segment>
    ) : (
      <h2>You are not eligible.</h2>
    );
  }
}

export default withRouter(VMS);
