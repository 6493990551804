import React, { Component } from 'react';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import moment from 'moment';
import reportsEnv from './reportsEnv';
import { getOrg } from './helper/helper';

import TimeAndAsset from './time-and-asset';
import DistanceInDay from './reports/DistanceInDay';
import DistanceInDayA from './reports/DistanceInDayA';
import DistanceInDayB from './reports/DistanceInDayB';
import DistanceInTime from './reports/DistanceInTime';
import DistanceInTimeA from './reports/DistanceInTimeA';
import ADC from './reports/ADC';
import Location from './reports/Location';
import LocationA from './reports/LocationA';
import RawData from './reports/RawData';
import Summary from './reports/Summary';
import DistanceA from './reports/DistanceA';
import DistanceB from './reports/DistanceB';
import DistanceC from './reports/DistanceC';
import Speed from './reports/Speed';
import Fuel from './reports/Fuel';
import FuelNew from './reports/FuelNew';
import RawFuel from './reports/RawFuel';
import RawTemp from './reports/RawTemperature';
import Ignition from './reports/Ignition';
import IgnitionNew from './reports/IgnitionNew';
import LocationLive from './reports/LocationLive';
import Geofence from './reports/Geofence';
import IgnitionA from './reports/IgnitionA';
import Halt from './reports/Halt';
import HaltA from './reports/HaltA';
import TPMS from './reports/TPMS';
import OBD from './reports/OBD';
import Temperature from './reports/Temperature';
import TemperatureA from './reports/TemperatureA';
import DigitalIndicator from './reports/DigitalIndicator';
import LocationLiveA from './reports/LocationLiveA';
import LocationLiveB from './reports/LocationLiveB';
import LocationLiveC from './reports/LocationLiveC';
import LocationLiveD from './reports/LocationLiveD';
import SummaryA from './reports/SummaryA';

class Report extends Component {
  state = {
    activeItem: '',
    hideOtherMenu: true,
  };

  componentDidMount() {
    window.localStorage.setItem(
      'reports_state',
      JSON.stringify({
        selectedAssets: [],
        startTime: moment().startOf('day'),
        endTime: moment().endOf('day'),
      }),
    );
    setTimeout(() => {
      this.setState({ activeItem: 'Time and Assets' });
    }, 1000);
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  showOtherMenu = (action) => {
    this.setState({ hideOtherMenu: !action });
  };

  isStaffAllowed = () => {
    return window.localStorage.getItem('staff') === 'true';
  };

  render() {
    const { activeItem, hideOtherMenu } = this.state;

    return (
      <Segment>
        {hideOtherMenu ? (
          <Menu
            pointing
            secondary
            style={{ overflowX: 'auto', overflowY: 'hidden' }}
          >
            <Menu.Item
              name='Time and Assets'
              active={activeItem === 'Time and Assets'}
              onClick={this.handleItemClick}
              style={{
                color: '#00B5AD',
                textTransform: 'uppercase',
              }}
            >
              <Icon name='setting' />
              Select Time and Assets
            </Menu.Item>

            {!reportsEnv.locationLive.hasOwnProperty('organizations') ||
            reportsEnv.locationLive.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live'
                active={activeItem === 'Location Live'}
                onClick={this.handleItemClick}
              >
                Location Live
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveA.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [A]'
                active={activeItem === 'Location Live [A]'}
                onClick={this.handleItemClick}
              >
                Location Live [A]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveB.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveB.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [B]'
                active={activeItem === 'Location Live [B]'}
                onClick={this.handleItemClick}
              >
                Location Live [B] [{reportsEnv.locationLiveB.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveC.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveC.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [C]'
                active={activeItem === 'Location Live [C]'}
                onClick={this.handleItemClick}
              >
                Location Live [C]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveD.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveD.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [D]'
                active={activeItem === 'Location Live [D]'}
                onClick={this.handleItemClick}
              >
                Location Live [D]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceA.hasOwnProperty('organizations') ||
            reportsEnv.distanceA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance [A]'
                active={activeItem === 'Distance [A]'}
                onClick={this.handleItemClick}
              >
                Distance [A] [{reportsEnv.distanceA.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceB.hasOwnProperty('organizations') ||
            reportsEnv.distanceB.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance [B]'
                active={activeItem === 'Distance [B]'}
                onClick={this.handleItemClick}
              >
                Distance [B] [{reportsEnv.distanceB.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceC.hasOwnProperty('organizations') ||
            reportsEnv.distanceC.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance [C]'
                active={activeItem === 'Distance [C]'}
                onClick={this.handleItemClick}
              >
                Distance [C] [{reportsEnv.distanceC.durationLimit}]
              </Menu.Item>
            ) : null}
          </Menu>
        ) : (
          <Menu pointing secondary style={{ overflowX: 'auto' }}>
            <Menu.Item
              name='Time and Assets'
              active={activeItem === 'Time and Assets'}
              onClick={this.handleItemClick}
              style={{
                color: '#00B5AD',
                textTransform: 'uppercase',
              }}
            >
              <Icon name='setting' />
              Select Time and Assets
            </Menu.Item>

            <div className='custom-divider' />
            {/* ..........COMMON.......... */}

            {!reportsEnv.locationLive.hasOwnProperty('organizations') ||
            reportsEnv.locationLive.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live'
                active={activeItem === 'Location Live'}
                onClick={this.handleItemClick}
              >
                Location Live
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceInDay.hasOwnProperty('organizations') ||
            reportsEnv.distanceInDay.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance in Day'
                active={activeItem === 'Distance in Day'}
                onClick={this.handleItemClick}
              >
                Distance in Day [{reportsEnv.distanceInDay.durationLimit}][
                {reportsEnv.distanceInDay.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceInTime.hasOwnProperty('organizations') ||
            reportsEnv.distanceInTime.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance in Time'
                active={activeItem === 'Distance in Time'}
                onClick={this.handleItemClick}
              >
                Distance in Time [{reportsEnv.distanceInTime.durationLimit}][
                {reportsEnv.distanceInTime.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.speed.hasOwnProperty('organizations') ||
            reportsEnv.speed.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Speed'
                active={activeItem === 'Speed'}
                onClick={this.handleItemClick}
              >
                Speed [{reportsEnv.speed.durationLimit}][
                {reportsEnv.speed.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.location.hasOwnProperty('organizations') ||
            reportsEnv.location.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location'
                active={activeItem === 'Location'}
                onClick={this.handleItemClick}
              >
                Location [{reportsEnv.location.durationLimit}][
                {reportsEnv.location.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationA.hasOwnProperty('organizations') ||
            reportsEnv.locationA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location [A]'
                active={activeItem === 'Location [A]'}
                onClick={this.handleItemClick}
              >
                Location [A] [{reportsEnv.locationA.durationLimit}] [
                {reportsEnv.locationA.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.summary.hasOwnProperty('organizations') ||
            reportsEnv.summary.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Summary'
                active={activeItem === 'Summary'}
                onClick={this.handleItemClick}
              >
                Summary [{reportsEnv.summary.durationLimit}][
                {reportsEnv.summary.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.summaryA.hasOwnProperty('organizations') ||
            reportsEnv.summaryA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Summary [A]'
                active={activeItem === 'Summary [A]'}
                onClick={this.handleItemClick}
              >
                Summary [A] [{reportsEnv.summary.durationLimit}][
                {reportsEnv.summary.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.geofence.hasOwnProperty('organizations') ||
            reportsEnv.geofence.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Geofence'
                active={activeItem === 'Geofence'}
                onClick={this.handleItemClick}
              >
                Geofence [{reportsEnv.geofence.durationLimit}][
                {reportsEnv.geofence.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.ignition.hasOwnProperty('organizations') ||
            !reportsEnv.ignition.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Ignition'
                active={activeItem === 'Ignition'}
                onClick={this.handleItemClick}
              >
                Ignition [{reportsEnv.ignition.durationLimit}][
                {reportsEnv.ignition.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.halt.hasOwnProperty('organizations') ||
            reportsEnv.halt.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Halt'
                active={activeItem === 'Halt'}
                onClick={this.handleItemClick}
              >
                Halt [{reportsEnv.halt.durationLimit}][
                {reportsEnv.halt.assetLimit}]
              </Menu.Item>
            ) : null}

            <div className='custom-divider' />
            {/* ..........For Specific Devices.......... */}

            {!reportsEnv.ignitionNew.hasOwnProperty('organizations') ||
            reportsEnv.ignitionNew.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Ignition [New]'
                active={activeItem === 'Ignition [New]'}
                onClick={this.handleItemClick}
              >
                Ignition(New) [{reportsEnv.ignitionNew.durationLimit}][
                {reportsEnv.ignitionNew.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.fuel.hasOwnProperty('organizations') ||
            reportsEnv.fuel.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Fuel [Old]'
                active={activeItem === 'Fuel [Old]'}
                onClick={this.handleItemClick}
              >
                Fuel [{reportsEnv.fuel.durationLimit}][
                {reportsEnv.fuel.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.fuelNew.hasOwnProperty('organizations') ||
            !reportsEnv.fuelNew.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Fuel'
                active={activeItem === 'Fuel'}
                onClick={this.handleItemClick}
              >
                Fuel [{reportsEnv.fuelNew.durationLimit}][
                {reportsEnv.fuelNew.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.adc.hasOwnProperty('organizations') ||
            reportsEnv.adc.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='ADC'
                active={activeItem === 'ADC'}
                onClick={this.handleItemClick}
              >
                ADC [{reportsEnv.adc.durationLimit}][
                {reportsEnv.adc.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.temperature.hasOwnProperty('organizations') ||
            reportsEnv.temperature.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Temperature'
                active={activeItem === 'Temperature'}
                onClick={this.handleItemClick}
              >
                Temperature [{reportsEnv.temperature.durationLimit}][
                {reportsEnv.temperature.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.temperatureA.hasOwnProperty('organizations') ||
            reportsEnv.temperatureA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Temperature [A]'
                active={activeItem === 'Temperature [A]'}
                onClick={this.handleItemClick}
              >
                Temperature [A] [{reportsEnv.temperatureA.durationLimit}][
                {reportsEnv.temperatureA.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.digitalInput.hasOwnProperty('organizations') ||
            reportsEnv.digitalInput.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Digital Indicator'
                active={activeItem === 'Digital Indicator'}
                onClick={this.handleItemClick}
              >
                Digital Indicator [{reportsEnv.digitalInput.durationLimit}][
                {reportsEnv.digitalInput.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.obd.hasOwnProperty('organizations') ||
            reportsEnv.obd.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='OBD'
                active={activeItem === 'OBD'}
                onClick={this.handleItemClick}
              >
                OBD [{reportsEnv.obd.durationLimit}][
                {reportsEnv.obd.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.tpms.hasOwnProperty('organizations') ||
            reportsEnv.tpms.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='TPMS'
                active={activeItem === 'TPMS'}
                onClick={this.handleItemClick}
              >
                TPMS [{reportsEnv.tpms.durationLimit}][
                {reportsEnv.tpms.assetLimit}]
              </Menu.Item>
            ) : null}

            <div className='custom-divider' />
            {/* .......... Custom .......... */}

            {!reportsEnv.locationLiveA.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [A]'
                active={activeItem === 'Location Live [A]'}
                onClick={this.handleItemClick}
              >
                Location Live [A]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveB.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveB.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [B]'
                active={activeItem === 'Location Live [B]'}
                onClick={this.handleItemClick}
              >
                Location Live [B] [{reportsEnv.locationLiveB.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveC.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveC.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [C]'
                active={activeItem === 'Location Live [C]'}
                onClick={this.handleItemClick}
              >
                Location Live [C]
              </Menu.Item>
            ) : null}

            {!reportsEnv.locationLiveD.hasOwnProperty('organizations') ||
            reportsEnv.locationLiveD.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Location Live [D]'
                active={activeItem === 'Location Live [D]'}
                onClick={this.handleItemClick}
              >
                Location Live [D]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceA.hasOwnProperty('organizations') ||
            reportsEnv.distanceA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance [A]'
                active={activeItem === 'Distance [A]'}
                onClick={this.handleItemClick}
              >
                Distance [A] [{reportsEnv.distanceA.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceB.hasOwnProperty('organizations') ||
            reportsEnv.distanceB.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance [B]'
                active={activeItem === 'Distance [B]'}
                onClick={this.handleItemClick}
              >
                Distance [B] [{reportsEnv.distanceB.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceC.hasOwnProperty('organizations') ||
            reportsEnv.distanceC.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance [C]'
                active={activeItem === 'Distance [C]'}
                onClick={this.handleItemClick}
              >
                Distance [C] [{reportsEnv.distanceC.durationLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.ignitionA.hasOwnProperty('organizations') ||
            reportsEnv.ignitionA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Ignition [A]'
                active={activeItem === 'Ignition [A]'}
                onClick={this.handleItemClick}
              >
                Ignition [A] [{reportsEnv.ignitionA.durationLimit}][
                {reportsEnv.ignitionA.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceInDayA.hasOwnProperty('organizations') ||
            reportsEnv.distanceInDayA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance in Day [A]'
                active={activeItem === 'Distance in Day [A]'}
                onClick={this.handleItemClick}
              >
                Distance in Day [A] [{reportsEnv.distanceInDayA.durationLimit}][
                {reportsEnv.distanceInDayA.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceInDayB.hasOwnProperty('organizations') ||
            reportsEnv.distanceInDayB.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance in Day [B]'
                active={activeItem === 'Distance in Day[B]'}
                onClick={this.handleItemClick}
              >
                Distance in Day [B] [{reportsEnv.distanceInDayB.durationLimit}][
                {reportsEnv.distanceInDayB.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.haltA.hasOwnProperty('organizations') ||
            reportsEnv.haltA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Halt [A]'
                active={activeItem === 'Halt [A]'}
                onClick={this.handleItemClick}
              >
                Halt [A][{reportsEnv.haltA.durationLimit}][
                {reportsEnv.haltA.assetLimit}]
              </Menu.Item>
            ) : null}

            {!reportsEnv.distanceInTimeA.hasOwnProperty('organizations') ||
            reportsEnv.distanceInTimeA.organizations.includes(getOrg()) ? (
              <Menu.Item
                name='Distance in Time [A]'
                active={activeItem === 'Distance in Time [A]'}
                onClick={this.handleItemClick}
              >
                Distance in Time [A] [{reportsEnv.distanceInTimeA.durationLimit}
                ][
                {reportsEnv.distanceInTimeA.assetLimit}]
              </Menu.Item>
            ) : null}

            <div className='custom-divider' />
            {/* .......... Staff Only .......... */}

            {this.isStaffAllowed() ? (
              <Menu.Item
                name='Raw Data'
                active={activeItem === 'Raw Data'}
                onClick={this.handleItemClick}
              >
                Raw Data [{reportsEnv.rawData.durationLimit}][
                {reportsEnv.rawData.assetLimit}]
              </Menu.Item>
            ) : null}

            {this.isStaffAllowed() ? (
              <Menu.Item
                name='Raw Fuel'
                active={activeItem === 'Raw Fuel'}
                onClick={this.handleItemClick}
              >
                Raw Fuel [{reportsEnv.rawFuel.durationLimit}][
                {reportsEnv.rawFuel.assetLimit}]
              </Menu.Item>
            ) : null}

            {this.isStaffAllowed() ? (
              <Menu.Item
                name='Raw Temperature'
                active={activeItem === 'Raw Temperature'}
                onClick={this.handleItemClick}
              >
                Raw Temperature [{reportsEnv.rawTemperature.durationLimit}][
                {reportsEnv.rawTemperature.assetLimit}]{' '}
              </Menu.Item>
            ) : null}
          </Menu>
        )}

        <Segment>
          {activeItem === 'Time and Assets' && (
            <TimeAndAsset showOtherMenu={this.showOtherMenu} />
          )}
          {activeItem === 'Distance in Day' && <DistanceInDay />}
          {activeItem === 'Distance in Time' && <DistanceInTime />}
          {activeItem === 'Speed' && <Speed />}
          {activeItem === 'Location' && <Location />}
          {activeItem === 'Location [A]' && <LocationA />}
          {activeItem === 'Location Live' && <LocationLive />}
          {activeItem === 'Summary' && <Summary />}
          {activeItem === 'Summary [A]' && <SummaryA />}
          {activeItem === 'Geofence' && <Geofence />}
          {activeItem === 'Ignition' && <Ignition />}
          {activeItem === 'Halt' && <Halt />}
          {activeItem === 'Halt [A]' && <HaltA />}
          {activeItem === 'Distance in Time [A]' && <DistanceInTimeA />}
          {activeItem === 'Ignition [New]' && <IgnitionNew />}
          {activeItem === 'Fuel [Old]' && <Fuel />}
          {activeItem === 'Fuel' && <FuelNew />}
          {activeItem === 'ADC' && <ADC />}
          {activeItem === 'Temperature' && <Temperature />}
          {activeItem === 'Temperature [A]' && <TemperatureA />}
          {activeItem === 'Digital Indicator' && <DigitalIndicator />}
          {activeItem === 'OBD' && <OBD />}
          {activeItem === 'TPMS' && <TPMS />}
          {activeItem === 'Distance [A]' && <DistanceA />}
          {activeItem === 'Distance [B]' && <DistanceB />}
          {activeItem === 'Distance [C]' && <DistanceC />}
          {activeItem === 'Ignition [A]' && <IgnitionA />}
          {activeItem === 'Location Live [A]' && <LocationLiveA />}
          {activeItem === 'Location Live [B]' && <LocationLiveB />}
          {activeItem === 'Location Live [C]' && <LocationLiveC />}
          {activeItem === 'Location Live [D]' && <LocationLiveD />}
          {activeItem === 'Distance in Day [A]' && <DistanceInDayA />}
          {activeItem === 'Distance in Day [B]' && <DistanceInDayB />}
          {activeItem === 'Raw Data' && <RawData />}
          {activeItem === 'Raw Fuel' && <RawFuel />}
          {activeItem === 'Raw Temperature' && <RawTemp />}
        </Segment>
      </Segment>
    );
  }
}

export default Report;
