import React from 'react';
import { Grid, Tab, Form, Button, Icon, Search } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Spinner from '../UI/Spinner/Spinner';
import { Link } from 'react-router-dom';
import { REGULAR_PATH } from '../../../env';
import moment from 'moment';

const baseURL = REGULAR_PATH;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedAssets: [],
      name: 'DEFAULT',
      password: '',
      email: 'Not provided',
      emailVerified: false,
      mobile: 'Not provided',
      mobileVerified: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      loader: false,
      appNotification: false,
      mailNotification: false,
      smsNotification: false,
      hasNotificationSetting: false,
      assetFilter: {
        id: false,
        name: false,
      },
      assetSort: {
        valid: '',
        active: 'asc',
      },
    };

    this.token = window.localStorage.getItem('user');
  }

  componentDidMount() {
    axios
      .get(`${baseURL}/user/profile?access_token=${this.token}`)
      .then((response) => {
        const {
          password,
          name,
          email,
          email_verified,
          mobile,
          mobile_verified,
        } = response.data;
        this.setState({
          password: password,
          name: name,
          emailVerified: email_verified,
          email: email,
          mobile: mobile,
          mobileVerified: mobile_verified,
        });
        return axios.get(`${baseURL}/user/assets?access_token=${this.token}`);
      })
      .then((response) => {
        const { assets } = response.data;
        this.setState({
          connectedAssets: assets.sort((a, b) => (a.name > b.name ? 1 : -1)),
        });
      })
      .catch((error) => {});
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { currentPassword, newPassword } = this.state;

    let header = {
      headers: { 'Content-Type': 'text/plain' },
    };
    if (this.state.password) {
      let body = {
        old_password: currentPassword,
        new_password: newPassword,
      };
      if (currentPassword !== '' && newPassword.length > 5) {
        axios
          .put(
            `${baseURL}/user/change_password?access_token=${this.token}`,
            body,
            header,
          )
          .then((response) => {
            //(response.data.success);
            if (response.data.success) {
              toast.success('Successfully Changed !', {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.error('Please provide your correct password !', {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch(() => {
            toast.error('Please provide your correct password !', {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    } else {
      if (newPassword.length > 5) {
        let body = {
          new_password: newPassword,
        };
        axios
          .post(
            `${baseURL}/user/new_password?access_token=${this.token}`,
            body,
            header,
          )
          .then((response) => {
            if (response.status) {
              toast.success('Successfully inserted !', {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            //(error);
            toast.error('Please provide your password !', {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        toast.error('Please provide your password minimum six character!', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    this.setState({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      loader: true,
    });
  };

  handleAssetFilter = (column, action) => {
    if (column === 'id') {
      this.setState({ assetFilter: { id: action, name: false } });
    } else if (column === 'name') {
      this.setState({ assetFilter: { id: false, name: action } });
    }
  };

  handleAssetSort = (column, action) => {
    if (column === 'valid') {
      this.setState({ assetSort: { valid: action, active: false } });
    } else if (column === 'active') {
      this.setState({ assetSort: { valid: false, active: action } });
    }
  };

  render() {
    const {
      connectedAssets,
      name,
      password,
      email,
      mobile,
      emailVerified,
      mobileVerified,
      currentPassword,
      newPassword,
      confirmPassword,
      assetFilter,
      assetSort,
    } = this.state;

    let connectedAssetsView;
    let currentPasswordComponent;
    let newAndConfirmedPasswordMatch;
    if (newPassword.length > 0 && confirmPassword.length > 0) {
      if (newPassword === confirmPassword) {
        newAndConfirmedPasswordMatch = true;
      } else {
        newAndConfirmedPasswordMatch = false;
      }
    } else {
      newAndConfirmedPasswordMatch = false;
    }

    if (connectedAssets.length > 0) {
      connectedAssetsView = connectedAssets
        .filter(({ asset_id, name }) => {
          if (typeof assetFilter.id === 'string' && assetFilter.id) {
            return asset_id
              .toLowerCase()
              .includes(assetFilter.id.toLowerCase());
          } else if (typeof assetFilter.name === 'string' && assetFilter.name) {
            return name.toLowerCase().includes(assetFilter.name.toLowerCase());
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          const { valid, active } = assetSort;
          if (valid === 'asc') {
            return a.valid_till.$date - b.valid_till.$date;
          } else if (valid === 'dsc') {
            return b.valid_till.$date - a.valid_till.$date;
          } else if (active === 'asc') {
            return a.active - b.active;
          } else if (active === 'dsc') {
            return b.active - a.active;
          }
          return true;
        })
        .map((asset, index) => (
          <tr key={index}>
            <td>{asset.asset_id}</td>
            <td>{asset.name}</td>
            {window.localStorage.getItem('organization') ===
            '6020bcbf1d41c84da5bcb6a3' ? (
              <td>{asset.chasis_number}</td>
            ) : null}
            <td>
              {asset.valid_till
                ? moment(asset.valid_till.$date).format('MMMM Do, YYYY')
                : null}
            </td>
            <td>{asset.active ? 'Yes' : 'No'}</td>
          </tr>
        ));
    } else {
      connectedAssetsView = 'No data found';
    }

    if (password) {
      currentPasswordComponent = (
        <Form.Group>
          <Form.Input
            label='Current password'
            type='password'
            placeholder='Password'
            name='currentPassword'
            value={currentPassword}
            onChange={this.handleChange}
          />
        </Form.Group>
      );
    } else {
      currentPasswordComponent = null;
    }

    const panes = [
      {
        menuItem: 'Basic info',
        render: () => (
          <Tab.Pane attached={false}>
            <div className='profile-table'>
              <table>
                <tbody>
                  <tr>
                    <td className='width-200'>Name</td>
                    <td>{name}</td>
                  </tr>
                  {mobile && (
                    <tr>
                      <td className='width-200'>Mobile number</td>
                      <td>{mobile}</td>
                    </tr>
                  )}
                  {mobile && (
                    <tr>
                      <td className='width-200'>Mobile verified</td>
                      <td>{mobileVerified ? 'Yes' : 'No'}</td>
                    </tr>
                  )}
                  {email && (
                    <tr>
                      <td className='width-200'>Email</td>
                      <td>{email}</td>
                    </tr>
                  )}
                  {email && (
                    <tr>
                      <td className='width-200'>Email verified</td>
                      <td>{emailVerified ? 'Yes' : 'No'}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Assets',
        render: () => (
          <Tab.Pane attached={false}>
            <div className='profile-table'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h3>Assets</h3>
                <p>
                  Total: {connectedAssets.length}, Active:
                  {connectedAssets.filter(({ active }) => active).length},
                  Inactive:
                  {connectedAssets.filter(({ active }) => !active).length}
                </p>
                <a
                  style={{
                    marginLeft: '20px',
                    marginRight: '20px',
                    textDecoration: 'none',
                  }}
                  href={`${REGULAR_PATH}/assets/list_download?access_token=${this.token}`}
                >
                  <Button>Download</Button>
                </a>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>
                      {assetFilter.id ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Search
                            onSearchChange={(e, data) =>
                              this.handleAssetFilter('id', data.value)
                            }
                            value={
                              typeof assetFilter.id === 'string'
                                ? assetFilter.id
                                : ''
                            }
                            placeholder='Search Here...'
                            showNoResults={false}
                            style={{ width: '200px' }}
                          />
                          <Button
                            icon
                            onClick={() => this.handleAssetFilter('id', false)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          >
                            <Icon name='close' />
                          </Button>
                        </div>
                      ) : (
                        <span>
                          Asset ID
                          <Icon
                            name='search'
                            onClick={() => this.handleAssetFilter('id', true)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          />
                        </span>
                      )}
                    </th>
                    <th>
                      {assetFilter.name ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Search
                            onSearchChange={(e, data) =>
                              this.handleAssetFilter('name', data.value)
                            }
                            value={
                              typeof assetFilter.name === 'string'
                                ? assetFilter.name
                                : ''
                            }
                            placeholder='Search Here...'
                            showNoResults={false}
                            style={{ width: '200px' }}
                          />
                          <Button
                            icon
                            onClick={() =>
                              this.handleAssetFilter('name', false)
                            }
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          >
                            <Icon name='close' />
                          </Button>
                        </div>
                      ) : (
                        <span>
                          Name
                          <Icon
                            name='search'
                            onClick={() => this.handleAssetFilter('name', true)}
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          />
                        </span>
                      )}
                    </th>
                    {window.localStorage.getItem('organization') ===
                    '6020bcbf1d41c84da5bcb6a3' ? (
                      <th>Chassis Number</th>
                    ) : null}
                    <th>
                      <span>Bill Clear Up To</span>
                      <Icon
                        name={
                          assetSort.valid === 'asc'
                            ? 'sort ascending'
                            : assetSort.valid === 'dsc'
                              ? 'sort descending'
                              : 'sort'
                        }
                        onClick={() =>
                          this.handleAssetSort(
                            'valid',
                            assetSort.valid === 'asc' ? 'dsc' : 'asc',
                          )
                        }
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                      />
                    </th>
                    <th>
                      <span>Active</span>
                      <Icon
                        name={
                          assetSort.active === 'asc'
                            ? 'sort ascending'
                            : assetSort.active === 'dsc'
                              ? 'sort descending'
                              : 'sort'
                        }
                        onClick={() =>
                          this.handleAssetSort(
                            'active',
                            assetSort.active === 'asc' ? 'dsc' : 'asc',
                          )
                        }
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{connectedAssetsView}</tbody>
              </table>
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Billing',
        render: () => (
          <Tab.Pane attached={false}>
            <Link to='/monthlybill'>
              <span style={{ marginLeft: '10px' }}>
                {' '}
                <Button content='Click here ' />{' '}
              </span>
            </Link>
            <span>To Pay your Bill</span>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Change password',
        render: () => (
          <Tab.Pane attached={false}>
            <div>
              <Form onSubmit={this.handleSubmit}>
                {currentPasswordComponent}
                <Form.Group>
                  <Form.Input
                    label='New password'
                    type='password'
                    placeholder='Password'
                    name='newPassword'
                    value={newPassword}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    label='Confirm password'
                    type='password'
                    placeholder='Password'
                    name='confirmPassword'
                    value={confirmPassword}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: '10px' }}>
                  <Form.Button
                    disabled={!newAndConfirmedPasswordMatch}
                    content='Submit'
                  />
                </Form.Group>
              </Form>
            </div>
          </Tab.Pane>
        ),
      },
    ];
    if (name !== 'DEFAULT') {
      return (
        <div>
          <Grid divided='vertically' style={style.containerHeader}>
            <Grid.Row columns={4}>
              <Grid.Column>
                <div className='border-right'>
                  <p className='minimal-information-header'>
                    <strong> {name} </strong>
                  </p>
                  <dl className='minimal-information'>Consumer</dl>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='border-right'>
                  <p className='minimal-information-header'>
                    <strong>{connectedAssets.length}</strong>
                  </p>
                  <dl className='minimal-information'>Assets</dl>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='border-right'>
                  <p className='minimal-information-header'>
                    <strong> {mobile || 'Empty'} </strong>
                  </p>
                  <dl className='minimal-information'>Mobile</dl>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div>
                  <p className='minimal-information-header'>
                    <strong> {email || 'Empty'} </strong>
                  </p>
                  <dl className='minimal-information'>Email</dl>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className='menu-x'>
            <Tab
              menu={{ secondary: true }}
              panes={panes}
              style={style.containerBotton}
              className='profile-tab'
            />
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const style = {
  containerHeader: {
    marginRight: '10%',
    marginLeft: '10%',
  },
  containerBotton: {
    marginRight: '10%',
    marginLeft: '10%',
  },
};

export default UserProfile;
