import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirm: '',
      errMessage: '',
      color: 'ui message info',
      success: false,
    };
  }

  componentWillMount() {
    // this.setState({ success: false });
    if (this.props.routeParams.id !== undefined) {
      // ("ID___>>", this.props.routeParams.id);
      axios
        .get(
          `${REGULAR_PATH}/auth/reset_password/email_verify?id=${this.props.routeParams.id}`
        )
        .then((res) => {
          if (res.data.status === 'user verified') {
            this.setState({
              success: true,
            });
          } else if (res.data.status === 'link expired') {
            this.setState({
              errMessage: res.data.status,
              color: 'ui message error',
            });
          }
        })
        .catch((e) => {
          // ('red error');
          this.setState({
            errMessage: 'Invalid Link',
            color: 'ui message error',
          });
        });
    } else {
      this.setState({
        success: true,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.location.state !== undefined) {
      if (this.state.password === this.state.confirm) {
        var config = {
          'Access-Control-Allow-Origin': '*',
        };
        let body = {
          user: this.props.location.state.user,
          access_id: this.props.location.state.code,
          new_password: this.state.password,
        };
        axios
          .put(
            `${REGULAR_PATH}/auth/reset_password`,
            JSON.stringify(body),
            config
          )
          .then((res) => {
            this.setState({
              success: false,
              errMessage: res.data.status,
              color: 'ui message info',
            });
          });
      } else {
        this.setState({
          errMessage: 'Password does not match',
          color: 'ui message error',
        });
      }
    } else {
      if (this.state.password === this.state.confirm) {
        let body = {
          user: '',
          access_id: this.props.routeParams.id,
          new_password: this.state.password,
        };
        axios
          .put(
            `${REGULAR_PATH}/auth/reset_password`,
            JSON.stringify(body),
            config
          )
          .then((res) => {
            this.setState({
              success: false,
              errMessage: res.data.status,
              color: 'ui message info',
            });
          });
      } else {
        this.setState({
          errMessage: 'Password does not match',
          color: 'ui message error',
        });
      }
    }
  };

  setPassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  confirmPassword = (e) => {
    this.setState({
      confirm: e.target.value,
    });
  };

  render() {
    return (
      <div className='ui two column centered grid'>
        <div className='column'>
          <Link to='/'>
            <img
              className='logo'
              src='/images/Logo-1.png'
              width='300'
              alt='Finder logo'
            />
          </Link>
          <br />
          <div
            className='ui stacked segment'
            style={{ paddingBottom: '2em', color: '#000' }}
          >
            {this.state.success ? (
              <div>
                <form className='ui form grid' onSubmit={this.onSubmit}>
                  <h2 alig>Please Submit Your New Password</h2>
                  <div className='two column row'>
                    <div className='eight wide column'>
                      <input
                        onChange={this.setPassword}
                        value={this.state.password}
                        required
                        type='password'
                        minLength={6}
                        placeholder='Enter password'
                      />
                    </div>
                    <div className='eight wide column'>
                      <input
                        required
                        onChange={this.confirmPassword}
                        value={this.state.confirm}
                        type='password'
                        minLength={6}
                        placeholder='Confirm password'
                      />
                    </div>
                  </div>
                  <div className='one column row'>
                    <div className='sixteen wide column'>
                      <button
                        className='ui teal submit fluid button'
                        type='submit'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <Link to='/login' style={{ fontSize: '18px' }}>
                Login Now
              </Link>
            )}
          </div>
          {this.state.errMessage !== '' ? (
            <div className={this.state.color}>
              <p style={{ textTransform: 'capitalize' }}>
                {this.state.errMessage}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
