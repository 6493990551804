import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';

class UserVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: null,
      color: 'ui message info',
      errMessage: '',
      time: 180,
    };
  }

  componentDidMount() {
    this.timerInterval = setInterval(() => this.timer(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  timer = () => {
    this.setState((prevState) => ({
      time: prevState.time !== 0 ? prevState.time - 1 : 0,
    }));
  };

  submit = (e) => {
    e.preventDefault();
    axios
      .get(
        `${REGULAR_PATH}/auth/mobile_verify?code=${this.state.input}&access_token=${this.props.location.state.accessToken}`,
      )
      .then((res) => {
        // redirect now to dashboard
        if (res.data.status === 'code mismatch') {
          this.setState({
            color: 'ui message error',
            errMessage: 'Mobile verification code mismatch.',
          });
        } else if (res.data.status === 'code expired') {
          this.setState({
            success: false,
            color: 'ui message error',
            errMessage: 'Mobile verification code expired.',
          });
        } else {
          const { email, organization, mobile } =
            this.props.location.state.data;

          window.localStorage.setItem(
            'user',
            this.props.location.state.accessToken,
          );
          window.localStorage.setItem(
            'refresh',
            this.props.location.state.refreshToken,
          );
          window.localStorage.setItem('email', email);
          window.localStorage.setItem('organization', organization);
          if (/(01)(3|5|6|7|8|9)\d{8}$/.test(mobile)) {
            window.localStorage.setItem('mobile', mobile);
          }
          window.localStorage.setItem('mobile_verified', true);
          this.setState({
            success: true,
          });
        }
      });
  };

  handleChange = (e) => {
    this.setState({
      input: e.target.value,
    });
  };

  clickLink = () => {
    this.props.history.push('/');
  };

  resend = () => {
    this.setState({ time: 180 });
    axios
      .get(
        `${REGULAR_PATH}/auth/send_email?resend=ok&access_token=${this.props.location.state.accessToken}`,
      )
      .then((res) => {
        ('resending...');
      });
  };

  resendMobileCode = () => {
    this.setState({ time: 180 });
    axios
      .get(
        `${REGULAR_PATH}/auth/send_code?resend=ok&access_token=${this.props.location.state.accessToken}`,
      )
      .then((res) => {});
  };

  render() {
    const verifyType = this.props.location.state.verifyType;
    let verifyView;
    if (verifyType === 'sms sent' || verifyType === 'sms already sent') {
      verifyView = (
        <div
          className='ui stacked segment'
          style={{ paddingBottom: '2em', color: '#000' }}
        >
          <p>
            {verifyType === 'sms sent'
              ? 'A code has been sent to your mobile. Please enter the code below. It might take several minutes to get your code.'
              : 'A code has already been sent to your mobile. Please enter the code below. Please check your sms inbox.'}
          </p>
          <form onSubmit={this.submit} className='ui form grid'>
            <div className='two column row'>
              <div className='ten wide column'>
                <input
                  onChange={this.handleChange}
                  required
                  type='text'
                  placeholder='Enter Your Verification Code'
                />
              </div>
              <div className='six wide column'>
                <button className='ui teal submit fluid button' type='submit'>
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div>
            {!this.state.time ? (
              <div>
                Did not get your code?{' '}
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={this.resendMobileCode}
                >
                  Resend Code
                </p>
              </div>
            ) : (
              <p>
                {` Resend Code in ${Math.floor(this.state.time / 60)}:${
                  this.state.time % 60
                }`}
              </p>
            )}
          </div>
        </div>
      );
    } else if (
      verifyType === 'email sent' ||
      verifyType === 'email already sent'
    ) {
      verifyView = (
        <div
          className='ui stacked segment'
          style={{ paddingBottom: '2em', color: '#000' }}
        >
          {verifyType === 'email sent'
            ? 'Your email is not verified yet. You have to verify your email before login. An email has been sent to verify your email. Please check your inbox or spam.'
            : 'Your email is not verified yet. You have to verify your email before   login. An email has already been sent to verify your email. Please check your inbox or spam.'}
          <br />
          <br />
          <div>
            {!this.state.time ? (
              <div>
                Email not sent?{' '}
                <p style={{ cursor: 'pointer' }} onClick={this.resend}>
                  Resend email
                </p>
              </div>
            ) : (
              <p>
                {` Resend email in ${Math.floor(this.state.time / 60)}:${
                  this.state.time % 60
                }`}
              </p>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className='ui two column centered grid'>
        <div className='column'>
          <Link to='/'>
            <img
              className='logo'
              src='/images/Logo-1.png'
              width='300'
              style={{ marginBottom: '20px' }}
              alt='Finder logo'
            />
          </Link>

          {this.state.errMessage && !this.state.success ? (
            <div className={this.state.color}>
              <b>{this.state.errMessage}</b>
              <br />
            </div>
          ) : null}
          {this.state.success ? (
            <div className='ui message success'>
              <p>Your mobile number has been successfully verified.</p>
              <br />
              <Link onClick={this.clickLink} to='/'>
                Go to DashBoard
              </Link>
            </div>
          ) : (
            <div>{verifyView}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(UserVerify);
