import React, { Component } from 'react';
import JC400Stream from './stream/jc400/jc400-stream';
import JC120Stream from './stream/jc120-stream.js';
import { withRouter } from 'react-router-dom';

class MobileStream extends Component {
  render() {
    const message = 'You are in the wrong page.';
    const params = new URLSearchParams(this.props.location.search);

    if (params.size === 0) {
      return message;
    }

    const deviceType = params.get('deviceType') || '';
    const assetId = params.get('assetId') || '';
    const accessToken = params.get('accessToken') || '';

    if (deviceType && assetId && accessToken) {
      return (
        <div>
          <div style={{ paddingTop: '140px' }}>
            {deviceType === 'jc400p' ? (
              <JC400Stream assetId={assetId} accessToken={accessToken} />
            ) : null}
            {deviceType === 'jc120' ? (
              <JC120Stream assetId={assetId} accessToken={accessToken} />
            ) : null}
          </div>
        </div>
      );
    }

    return message;
  }
}

export default withRouter(MobileStream);
