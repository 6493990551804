import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errMessage: '',
      user: '',
      color: 'ui message info',
      sms: false,
      code: '',
      time: 180,
      status: '',
    };
  }

  componentDidMount() {
    this.timerInterval = setInterval(() => this.timer(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  timer = () => {
    this.setState((prevState) => ({
      time: prevState.time !== 0 ? prevState.time - 1 : 0,
    }));
  };

  resendMobileCode = () => {
    this.setState({ time: 180 });
    axios.get(
      `${REGULAR_PATH}/auth/reset_password?resend='ok'&user=${this.state.user}`,
    );
  };

  submit = (e) => {
    e.preventDefault();
    axios
      .get(`${REGULAR_PATH}/auth/reset_password?user=${this.state.user}`)
      .then((res) => {
        if (res.data.status === 'sms sent') {
          this.setState({
            status: 'sms sent',
            sms: true,
            time: 180,
            errMessage: '',
          });
        } else if (res.data.status === 'sms already sent') {
          this.setState({
            status: 'sms already sent',
            sms: true,
            time: 180,
            errMessage: '',
          });
        } else if (res.data.status === 'email sent') {
          this.setState({
            status: 'email sent',
            time: 180,
            email: true,
            errMessage: '',
          });
        } else if (res.data.status === 'email already sent') {
          this.setState({
            status: 'email already sent',
            time: 180,
            email: true,
            errMessage: '',
          });
        } else if (res.data.status === 'user registered with socialauth') {
          this.setState({
            errMessage:
              'Please sign in with your google account. You are not eligible for reset password.',
          });
        } else if (res.data.status === 'user not found') {
          this.setState({
            errMessage: `User not found. Are you sure "${this.state.user}" is correct?`,
            color: 'ui message warning',
          });
        } else if (res.data.status === 'verification required') {
          this.setState({
            errMessage: `"${this.state.user}" is not verified.`,
            color: 'ui message warning',
          });
        }
      });
  };

  inputChange = (e) => {
    this.setState({
      user: e.target.value,
    });
  };

  inputCode = (e) => {
    this.setState({
      code: e.target.value,
    });
  };

  submitCode = (e) => {
    e.preventDefault();
    // (this.state.user);
    axios
      .get(
        `${REGULAR_PATH}/auth/reset_password/mobile_verify?mobile=${this.state.user}&code=${this.state.code}`,
      )
      .then((res) => {
        if (res.data.status === 'user verified') {
          this.props.history.push({
            pathname: '/resetPassword',
            state: {
              user: this.state.user,
              code: this.state.code,
            },
          });
        } else if (res.data.status === 'code mismatch') {
          this.setState({
            errMessage: 'Code is not correct',
            color: 'ui message error',
          });
        } else if (res.data.status === 'code expired') {
          this.setState({
            errMessage: res.data.status,
            color: 'ui message error',
          });
        }
      })
      .catch((e) => {
        this.setState({
          errMessage: 'Something went wrong, try again',
          color: 'ui message error',
        });
      });
  };

  render() {
    return (
      <div className='ui two column centered grid'>
        <div className='column'>
          <Link to='/'>
            <img
              className='logo'
              src='/images/Logo-1.png'
              width='300'
              alt='Finder logo'
            />
          </Link>
          <br />
          <div
            className='ui stacked segment'
            style={{ paddingBottom: '2em', color: '#000' }}
          >
            {!this.state.email ? (
              !this.state.sms ? (
                <div>
                  <h3>Enter your registered mobile or email</h3>
                  <form className='ui form grid' onSubmit={this.submit}>
                    <div className='two column row'>
                      <div className='ten wide column'>
                        <input
                          required
                          onChange={this.inputChange}
                          type='text'
                          value={this.state.user}
                          pattern='[^@]+@[^@]+\.[a-zA-Z]{2,6}|9[6-8]\d{8}|^01[3-9]\d{8}$'
                          placeholder='Enter Your Mobile or Email'
                        />
                      </div>
                      <div className='six wide column'>
                        <button
                          className='ui teal submit fluid button'
                          type='submit'
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <h3>
                    {this.state.status === 'sms sent' &&
                      'A code has been sent to your mobile. Please enter the code below. It might take several minutes to get your code.'}
                    {this.state.status === 'sms already sent' &&
                      'A code has already been sent to your mobile. Please enter the code below. Please check your sms inbox.'}
                  </h3>
                  <form className='ui form grid' onSubmit={this.submitCode}>
                    <div className='two column row'>
                      <div className='ten wide column'>
                        <input
                          onChange={this.inputCode}
                          required
                          type='text'
                          value={this.state.code}
                          placeholder='Enter Your Verification Code'
                        />
                      </div>
                      <div className='six wide column'>
                        <button
                          className='ui teal submit fluid button'
                          type='submit'
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div align='center'>
                      {!this.state.time ? (
                        <div>
                          Did not get your code?{' '}
                          <p
                            style={{ cursor: 'pointer', textAlign: 'center' }}
                            onClick={this.resendMobileCode}
                          >
                            Resend Code
                          </p>
                        </div>
                      ) : (
                        <p style={{ textAlign: 'center' }}>
                          {` Resend Code in ${Math.floor(
                            this.state.time / 60,
                          )}:${this.state.time % 60}`}
                        </p>
                      )}
                    </div>
                  </form>
                </div>
              )
            ) : (
              <div>
                <h3>
                  {this.state.status === 'email sent' &&
                    'A password reset link has been sent to your email account.'}
                  {this.state.status === 'email already sent' &&
                    'A password reset link has already been sent to your email account.'}
                </h3>
                <div align='center'>
                  {!this.state.time ? (
                    <div>
                      Did not get your code?{' '}
                      <p
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        onClick={this.resendMobileCode}
                      >
                        Resend Code
                      </p>
                    </div>
                  ) : (
                    <p style={{ textAlign: 'center' }}>
                      {` Resend email in ${Math.floor(this.state.time / 60)}:${
                        this.state.time % 60
                      }`}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          {this.state.errMessage !== '' ? (
            <div className={this.state.color}>
              <p>{this.state.errMessage}</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(ForgetPassword);
