import React from 'react';
import { withRouter } from 'react-router-dom';
import { postData } from '../../helper/dataService.js';
import { REGULAR_PATH, AUTH_CONFIG } from '../../../env';

class IgnitionFleet extends React.Component {
  state = {
    status: 'Loading...',
  };

  componentDidMount() {
    const {
      location: {
        query: { secret_key, user_id },
      },
    } = this.props;
    if (user_id && secret_key) {
      postData(
        `${REGULAR_PATH}/auth/login`,
        JSON.stringify({
          client_id: AUTH_CONFIG.id,
          client_secret: AUTH_CONFIG.secret,
          grant_type: 'client_credentials',
          scope: '',
          timezone: new Date().getTimezoneOffset(),
          login_type: 'secretkey',
          user: user_id,
          secret_key,
        }),
      )
        .then(({ data: { access_token, refresh_token, user = {} } }) => {
          if (access_token && refresh_token) {
            this.setState({ status: 'Authentication Success' });
            const { email, _id, mobile, organization, mobile_verified } = user;
            window.localStorage.setItem('user', access_token);
            window.localStorage.setItem('refresh', refresh_token);
            window.localStorage.setItem('email', email);
            window.localStorage.setItem('user_id', _id);
            window.localStorage.setItem('organization', organization);
            window.localStorage.setItem('real_org', organization);
            if (/(01)(3|5|6|7|8|9)\d{8}$/.test(mobile)) {
              window.localStorage.setItem('mobile', mobile);
            }
            window.localStorage.setItem('mobile_verified', mobile_verified);
            window.localStorage.setItem('login_type', 'secret_key');
            this.props.history.push('/');
          } else {
            this.setState({ status: 'Authentication Failed' });
          }
        })
        .catch(() => {
          this.setState({ status: 'Authentication Failed' });
        });
    }
  }

  render() {
    return <h2 style={{ color: 'white' }}>{this.state.status}</h2>;
  }
}

export default withRouter(IgnitionFleet);
